<script setup lang="ts">
import type { Agency, File, JobPosting } from '~/types/api'

export interface VRoundedPictureProps {
    entity?: Agency | JobPosting
    file?: File
    size?: string
}

const props = defineProps<VRoundedPictureProps>()

const image = computed(() => props.entity?.image || props.file)
const src = computed(() => image.value?.relativePath)

const attrs = useAttrs()
const alt = computed(() => attrs.alt || image.value?.title)

const isValid = computed(() => !!src.value && !!image.value?.processable)

const imageSize = computed(() => props.size || '72px')
const imageFullSize = computed(() => parseInt(imageSize.value) * 2 + 'px')
</script>

<template>
    <NuxtPicture
        v-if="isValid && src"
        :class="$style.root"
        :sizes="imageFullSize"
        :width="imageFullSize"
        :height="imageFullSize"
        provider="interventionRequest"
        :modifiers="{
            crop: '1x1',
        }"
        :src="src"
        :alt="alt"
    />
    <div v-else :class="[$style.root, $style['root--placeholder']]">
        <SvgIcon name="unavailable" view-box="0 0 24 24" width="24" height="24" />
    </div>
</template>

<style lang="scss" module>
.root {
    display: inline-block;
    overflow: hidden;
    width: var(--v-agency-image-width, v-bind('imageSize'));
    border-radius: 50%;
    aspect-ratio: 1;
    flex-shrink: 0;

    img {
        width: 100%;
        height: auto;
    }

    &--placeholder {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: color(black-03);
        color: color(grey-400);
    }
}
</style>
